

























































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Statues} from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import { Product} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import { create, all } from 'mathjs'
import { mapState } from 'vuex';

const math = create(all);

@Component({
  components: {SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse },
  computed: mapState('products', {
    itemsLength: (state: any) => state.productsTable?.totalCount,
  }),
})
export default class ProductTable extends SygniTable<Product> {
  tableFields: any[] = [
    {key: 'code', sortable: true, class: ['code']},
    {key: 'investmentClientName', label: 'Investor name', sortable: true, class: ['investor-name']},
    {key: 'sourceName', sortable: true, class: 'source-name'},
    {key: 'ownerName', sortable: true, class: 'owner-name'},
    {key: 'type', sortable: true, class: ['type']},
    {key: 'agreementDate', sortable: true, class: ['agreement-date']},
    {key: 'repaymentScheduleGenerated', sortable: true, class: ['schedule-status'], label: 'Schedule status'},
    {key: 'status', sortable: true, class: ['status']},
    {key: 'value', sortable: true, class: ['value']},
  ]

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get computedTableFields() {
    const tableFields = this.tableFields

    if (this.hasAutenti) {
      tableFields.splice(5, 0, { key: 'autentiStatus', sortable: true, class: 'status', label: 'E-Signature status' })
      tableFields.splice(8, 0, {key: 'signedStatus', sortable: true, class: ['status']})
    }

    return tableFields
  }

  get filtersQuery() {
    return this.$store.getters['products/getProductsTableFiltersQuery'];
  }

  get totals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      if(item.value.currency) {
        if(totals[item.value.currency]) {
          totals[item.value.currency] = item.value.value ? math.number(math.add(math.bignumber(totals[item.value.currency]), math.bignumber(item.value.value))) : math.number(math.add(math.bignumber(totals[item.value.currency]), 0));
        } else {
          totals[item.value.currency] = item.value.value ? math.bignumber(math.bignumber(item.value.value)) : 0;
        }
      }
    })

    return totals;
  }

  getTooltipMessage(message: string) {
    return message?.length <= 35 ? '' : message
  }

  goToProfile(investor: any): void {
    if(investor.investmentClientId) {
      this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: investor.investmentClientId}})
    }
  }

  get autentiStatuses() {
    return this.$store.getters['templates/getAutentiDictionaries']?.statuses
  }

  autentiStatusClass(item: any) {
    const autentiStatus = item?.eSigned ? 'COMPLETED' : item?.autentiStatus ? item?.autentiStatus?.toUpperCase() : 'NONE'

    switch(autentiStatus) {
      case('NEW'): {
        return 'black'
      }
      case('UNKNOWN'):
      case('WITHDRAWING'): {
        return 'disabled';
      }
      case('COMPLETED'): {
        return 'primary';
      }
      case('PROCESSING'):
      case('DRAFT'): {
        return 'success';
      }
      case('ERROR'):
      case('WITHDRAWN'):
      case('REJECTED'): {
        return 'danger';
      }
    }
    return 'black';
  }

  autentiStatusText(item: any): string {
    const status = item?.eSigned ? 'COMPLETED' : item?.autentiStatus
    const autentiStatus = this.autentiStatuses?.find((el: any) => el?.value?.toUpperCase() === status?.toUpperCase())

    return autentiStatus?.label || item?.autentiStatus || ''
  }

  signedStatusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case('NEW'): {
        return 'black';
      }
      
      case('COMPLETED'): {
        return 'primary';
      }
      case('SENT'): {
        return 'success';
      }
      case('E-SIGNED'): {
        return 'success';
      }
    }
    return '';
  }

  signedStatusText(status: Statues): string {
    switch(status.toUpperCase()) {
      case('NEW'): {
        return 'New';
      }
      case('COMPLETED'): {
        return 'Completed';
      }
      case('SENT'): {
        return 'Sent';
      }
      case('E-SIGNED'): {
        return 'E-signed';
      }
    }
    return '';
  }

  statusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case(Statues.new): {
        return 'black';
      }
      case(Statues.active): {
        return 'primary';
      }
      case(Statues.invited): {
        return 'success';
      }
      case(Statues.awaiting): {
        return 'success';
      }
      case(Statues.pending): {
        return 'black';
      }
      case(Statues.rejected): {
        return 'danger';
      }
      case(Statues.closed): {
        return 'disabled';
      }
      case(Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch(status.toUpperCase()) {
      case(Statues.new): {
        return 'New';
      }
      case(Statues.active): {
        return 'Active';
      }
      case(Statues.invited): {
        return 'Awaiting';
      }
      case(Statues.awaiting): {
        return 'Awaiting';
      }
      case(Statues.pending): {
        return 'Pending';
      }
      case(Statues.rejected): {
        return 'Rejected';
      }
      case(Statues.closed): {
        return 'Closed';
      }
      case(Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  async getItems() {
    await this.$store.dispatch('products/getProducts', this.filtersQuery);
  }

  setQuery() {
    this.$store.commit('products/setProductsTableQuery', this.localTableQuery);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('products/setProductsTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('products/setProductsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  getRepaymentScheduleGeneratedTitle(value: any) {
    switch (value) {
      case true:
        return 'Generated'
      case false:
        return 'Not generated'
      default: 
        return value
    }
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('products/setProductsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.onMounted();
  }

  getProductSummaryLink(product: Product) {
    if (product.status === Statues.pending) {
      return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/accept-by-legal-entity/${product.id}`
    } else {
      return `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${product.id}/for-legal-entity`
    }
  }

  async goToProductSummary(product: Product) {
    this.$store.commit('investors/clearAnnexingData');
    await this.$router.push({ path: this.getProductSummaryLink(product) })
  }
}

