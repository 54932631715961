

































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BTable, BvTableField } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';
import { BACKEND_BASE_URL } from "@/shared/consts";
import Utils from "@/modules/shared/utils/utils";
import { Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import SygniPagination from '@/components/table/SygniPagination.vue';
import SygniFlagPicker from '@/components/inputs/SygniFlagPicker.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import Vue from 'vue';
import { TemplateActions, TemplateStatuses } from '../../modules/fund/modules/fundraising/modules/templates/store/types';
import { mapState } from 'vuex';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniTextArea, SygniSquareButton, SygniRectButton, SygniModal, SygniFlagPicker, SygniRadio, SygniInput, SygniDatePicker
  },
  computed: {
    ...mapState('templates', {
      itemsLength: (state: any) => state.templatesTable?.totalCount,
    }),
  }
})
export default class AgreementsTable extends SygniTable<any> {
  TemplateStatuses: any = TemplateStatuses;
  TemplateActions: any = TemplateActions;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  bulkOptionsMarginTop: number = 0;
  selectedBankAccount: any = null;
  paymentForSingleDocument: any = false;
  refresh: any = null;
  tableItems: any = [
    {
      documentName: 'Szablon umowy z klientem',
      author: 'John Doe',
      createdAt: '2022-08-25T13:10:42+00:00',
      updatedAt: '2022-08-25T13:10:42+00:00',
      useCount: 2,
      value: 100,
      currency: 'PLN',
    },
    {
      documentName: 'Umowa partnerska',
      author: 'Aleksander Przykładowy',
      createdAt: '2022-08-25T13:10:42+00:00',
      updatedAt: '2022-08-25T13:10:42+00:00',
      useCount: 3,
      value: 100000000,
      currency: 'PLN',
    },
    {
      documentName: 'Umowa kredytowa v2 21.10.2022 Eryk',
      author: 'Jan Kowalski',
      createdAt: '2022-08-25T13:10:42+00:00',
      updatedAt: '2022-08-25T13:10:42+00:00',
      useCount: 20,
      value: 250000,
      currency: 'PLN',
    },
  ];

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    // { key: 'selected', sortable: false, borderless: true, label: '' },
    { key: 'title', sortable: true, class: 'text-left', label: 'Title' },
    { key: 'authorName', class: 'text-left', sortable: true, borderless: true, label: 'Creator' },
    { key: 'createdAt', sortable: true, borderless: true, class: 'text-left', label: 'Created' },
    { key: 'updatedAt', class: 'text-left', sortable: true, borderless: true, label: 'Edited' },
    { key: 'isAttachment', class: 'text-center', sortable: true, borderless: true, label: 'Type' },
    { key: 'status', class: 'text-center', sortable: true, borderless: false, label: 'Status' },
    { key: 'actions', sortable: false, label: 'Actions' },
  ];

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get hasAutenti() {
    return this.activeUserData?.autenti?.enabled
  }

  get computedTableFields() {
    const tableFields = this.tableFields

    if (this.hasAutenti) {
      tableFields.splice(5, 0, { key: 'autentiEnabled', sortable: true, class: 'status', label: 'Autenti' })
    }

    return tableFields
  }

  getTooltipStatusMessage(item: any) {
    if (item.status == 'rejected') return item?.note ? item.note : '';

    return item.status == 'failed' ? item.errorMessage : '';
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.rejectionNote = '';
  }

  enableRejectionModal() {
    this.showRejectionModal = true;
  }

  getRowDataActionItems(items: Array<string>) {
    const totalItems = [...items, 'download'];

    return totalItems;
  }

  statusClass(status: string) {
    switch (status) {
      case (TemplateStatuses.DRAFT): return 'success';
      case (TemplateStatuses.ACTIVE): return 'primary';
      case (TemplateStatuses.ARCHIVED): return 'lead';
    }

    return 'black';
  }

  get parentLink() {
    return (this.$route.path.includes('company')) ? 'company' : 'fund';
  }

  get actionItems() {
    if (this.isMarketplaceAccountant) {
      return ['preview']
    }

    let actions: Array<string> = [];

    this.items.forEach((item: any) => {
      actions.push(...item.actions);
    });

    actions = actions.filter((action: string) => {
      return !(action == 'edit-payment-status' || action == 'preview' || action == 'approve' || action == 'reject');
    });

    actions = [...new Set(actions), 'download'];

    if (actions.indexOf('delete') != -1) {
      actions.push(actions.splice(actions.indexOf('delete'), 1)[0]);
    }

    return actions;
  }

  get formattedActionItems() {
    return this.actionItems.map((value: string, index: number) => {
      return {
        id: index + 1,
        key: value,
      }
    })
  }

  get actionItemsLength() {
    return this.actionItems.length;
  }

  get defaultPaymentPackageName() {
    const bankAccount = this.selectedBankAccount ? this.selectedBankAccount : 'xxxxx';
    const date = new Date();
    return `Payment package from ${bankAccount}, ${date.getFullYear()}-${Utils.pad(date.getMonth() + 1, 2)}-${Utils.pad(date.getDate() + 1, 2)} ${Utils.pad(date.getHours(), 2)}:${Utils.pad(date.getMinutes(), 2)}:${Utils.pad(date.getSeconds(), 2)}`;
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('templates/setTemplatesTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('templates/setTemplatesTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit(this.$attrs.sortingQueryAction, this.sortingQuery);
      this.getItems();
    });
  }

  async setTemplateStatus(id: string, action: TemplateActions) {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch(`templates/${action}`, id);
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }
    this.refreshTable();
  }

  refreshTable(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(() => {
      this.getItems();
    })
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.agreementsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.agreementsTable as BTable).selectAllRows();
    } else {
      (this.$refs.agreementsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  selectRowEl(index: number) {
    if (this.$refs.agreementsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.agreementsTable as AgreementsTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if (this.$refs.agreementsTable == undefined) return;

    const rows = ((this.$refs.agreementsTable as AgreementsTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  get agreementsTableQuery() {
    return this.$store.getters['accounting/getDocumentsTableQuery'];
  }

  selectRowDoc(item: any, index: number, add: boolean = false) {
    const offset = this.agreementsTableQuery.offset;
    this.$store.commit('accounting/setSelectedDocument', item);
    if (add) {
      this.$store.commit('accounting/setDocumentIndexes', index);
    } else {
      this.$store.commit('accounting/setDocumentIndexes', index + offset);
    }

    if (this.selectedDocument) {
      this.selectRowEl(index)
    } else {
      this.$store.commit('accounting/clearDocumentIndexes');
      this.deselectRowEl();
    }
  }

  onRowClicked(item: any) {
    this.goToPreview(item)
  }
  
  goToPreview(item: any) {
    this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/templates/${item?.id}/preview` });
  }

  toggleTableRow(item: any, index: number) {
    const selectedRowEl = (this.$refs.agreementsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    if (this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index].value;
    }

    if (item.value) {
      (this.$refs.agreementsTable as BTable).selectRow(index);
    } else {
      (this.$refs.agreementsTable as BTable).unselectRow(index);
    }
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  downloadDocument() {
    if (this.activeDocument == undefined) return;
    this.downloadFileByUrl(this.activeDocument);
  }

  setQuery() {
    this.$store.commit('templates/setTemplatesTableQuery', this.localTableQuery);
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, '');
    this.resetCheckboxes();
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el: any, index: any) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.pdfFilePath}`, file.fileName);
  }

  downloadMultipleDocuments() {
    const documentUrls = this.rowsSelected.map(el => {
      const item = this.items.find((item: any) => el.label == item.id);
      return { fileName: item.fileName, path: item.pdfFilePath };
    });
    Utils.downloadZippedFilesByUrls(documentUrls);
  }

  deleteRow(item: any) {
    this.$emit('deleteRow', item);
  }

  get filtersQuery() {
    return this.$store.getters['accounting/getDocumentsTableFiltersQuery'];
  }

  get selectedDocument() {
    return this.$store.getters['accounting/getSelectedDocument'];
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  validations() {
    return {
      selectedBankAccount: { required },
      customPaymentDate: { required: requiredIf(() => this.useCustomPaymentDate) }
    }
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();

    this.paginationInstance.showPageNumberOptions = this.showPageNumberOptions;
    this.paginationInstance.perPage = this.tableData.perPage;
    this.paginationInstance.totalRows = this.tableData.totalCount;
    this.paginationInstance.$mount();
    
    this.$nextTick(() => {
      const currentPage = (this.tableData?.query?.offset / this.tableData?.query?.limit) + 1
      this.paginationInstance.currentPage = currentPage
    })

    this.paginationInstance.$on('change', (currentPage: number) => {
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = this.tableData.perPage * (currentPage - 1);
      this.setQuery();
      this.getItems();
      this.$scrollTo(((this.$refs.agreementsTable as AgreementsTable).$el as HTMLElement));
    });
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if (this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = 0;
      this.setQuery();
      this.getItems();
      this.changePerPageOption();
    });

    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;

    const isAgreementsTable = this.$el.classList.contains('agreements-table');
    const isWhistleblowerTable = this.$el.classList.contains('whistleblower-table');
    const isPortfolioTable = this.$el.classList.contains('portfolio-table');

    if (isAgreementsTable) {
      this.$el.querySelector('.agreements-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if (isWhistleblowerTable) {
      this.$el.querySelector('.whistleblower-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if (isPortfolioTable) {
      this.$el.querySelector('.portfolio-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else {
      this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    }
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.agreementsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('accounting/setDocumentsTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }
}

