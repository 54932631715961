











































import { FlagOption } from '@/modules/genprox/store/types';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from "vue-property-decorator";
import SygniLoader from '../layout/SygniLoader.vue';
import SygniCheckbox from './SygniCheckbox.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniFlag from '../layout/SygniFlag.vue';
import { BPopover } from 'bootstrap-vue';

@Component({
  components: { SygniCheckbox, SygniLinkButton, SygniLoader, SygniFlag, BPopover }
})
export default class SygniNetworkPicker extends Vue {
  @Prop() itemData: any;
  @Prop({ default: 'advisor' }) type: 'advisor' | 'campaign';
  @Prop({ default: false }) disabled: boolean;
  showDropdown: boolean = false;
  isLoading: boolean = false;

  get actions() {
    return {
      add: this.type === 'advisor' ? 'advisors/addAdvisorToNetwork' : 'campaigns/addCampaignToNetwork',
      delete: this.type === 'advisor' ? 'advisors/deleteAdvisorFromNetwork' : 'campaigns/deleteCampaignFromNetwork',
    }
  }

  isCoordinator(id: string) {
    return this.itemData?.coordinatorIds?.includes(id) || false
  }

  get selectedNetworks() {
    return this.$store.getters['genprox/networks'].map((el: any) => {
      return {
        id: el.id,
        name: el.name,
        value: this.itemData?.brokerNetworkIds?.includes(el.id) ? true : false,
      }
    })
  }

  get selectedFlags(): Array<FlagOption> {
    return this.$store.getters['genprox/flags'].map((el: FlagOption) => {
      return {
        id: el.id,
        name: el.name,
        value: this.itemData?.flags?.includes(el.id) ? true : false,
        color: el.color
      }
    })
  }

  get selectedNetworksFiltered() {
    return this.selectedNetworks.filter((el: FlagOption) => {
      return el.value
    });
  }

  get selectedNetworkToShow() {
    return this.selectedNetworksFiltered.sort((a: any, b: any) => (a.name.length > b.name.length) ? 1 : -1).slice(0, 1)[0];
  }

  get selectedNetworksLabel() {
    return this.selectedNetworksFiltered.sort((a: any, b: any) => (a.name.length > b.name.length) ? 1 : -1).slice(0, 2)?.map((el: any) => el?.name)?.join(', ');
  }

  get counter() {
    return (this.selectedNetworksFiltered.length > 2) ? this.selectedNetworksFiltered.length - 2 : 0;
  }

  get missingNetworks() {
    return this.selectedNetworks?.filter((el: any) => !el.value)
  }

  async selectAllNetworks() {
    if (!this.missingNetworks?.length) {
      return;
    }

    const actionName = this.type === 'advisor' ? 'advisors/addAdvisorToNetwork' : 'campaigns/addCampaignToNetwork'
    this.isLoading = true;

    try {
      const promises: any[] = []

      this.missingNetworks?.forEach((network: any) => {
        const payload: any = {
          id: this.itemData?.id,
          networkId: network?.id,
        }

        promises.push(this.$store.dispatch(actionName, payload))
      })

      await Promise.all(promises);

      this.$emit('changeNetwork');
      this.isLoading = false;

    } catch (e) {
      this.$emit('changeNetwork');
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        type: 'error',
        title: 'An error occurred.',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async deselectAllNetworks() {
    if (this.missingNetworks?.length) {
      return;
    }

    const actionName = this.type === 'advisor' ? 'advisors/deleteAdvisorFromNetwork' : 'campaigns/deleteCampaignFromNetwork'
    this.isLoading = true;

    try {
      const promises: any[] = []

      this.selectedNetworks?.forEach((network: any) => {
        const payload: any = {
          id: this.itemData?.id,
          networkId: network?.id,
        }

        promises.push(this.$store.dispatch(actionName, payload))
      })

      await Promise.all(promises);

      this.$emit('changeNetwork');
      this.isLoading = false;

    } catch (e) {
      this.$emit('changeNetwork');
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        type: 'error',
        title: 'An error occurred.',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async toggleNetwork(network: any) {
    if (this.disabled) {
      return
    }
    
    this.isLoading = true;

    try {
      const actionName = (!network.value) ? this.actions.add : this.actions.delete;

      const payload: any = {
        id: this.itemData?.id,
        networkId: network.id,
      };

      await this.$store.dispatch(actionName, payload)
      network.value = !(network.value);
      this.$emit('changeNetwork', network.id);
      this.isLoading = false;
    } catch(e) {
      this.$emit('changeNetwork');
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        type: 'error',
        title: 'An error occurred.',
        text: this.$t(errorMessage).toString()
      });
    }
  }
}
