import { TableData } from "@/modules/genprox/modules/profile/store/types";

export interface TemplatesState {
  templatesTable: TableData<any>,
  templatesTableIsBusy: boolean,
  autentiDictionaries: any,
}

export interface Template {
  id?: string,
  title: string,
  description: string | null,
  content: string,
  status: TemplateStatuses,
  isAttachment: boolean,
  attachments: TemplateAttachment[],
  authorName?: string,
  createdAt?: string,
  updatedAt?: string,
}

export interface TemplateAttachment {
  id?: string,
  name: string,
  type: 'TEMPLATE' | 'FILE',
  referenceId: string,
  path?: string | null,
  sendToAutenti?: boolean,
}

export interface AttachmentListItem {
  selected: boolean,
  referenceId: string,
  name: string,
}

export interface AttachmentObject {
  fileId: string,
  fileName: string,
}

export interface AgreementObject {
  agreementFileId: string,
  agreementName: string,
  attachments: AttachmentObject[],
}

export enum TemplateStatuses {
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  ACTIVE = 'ACTIVE',
}

export enum TemplateActions {
  DRAFT = 'draftTemplate',
  ARCHIVED = 'archiveTemplate',
  ACTIVE = 'activateTemplate',
}

export interface AutentiTag {
  id: string,
  description: string,
  type: string,
}

export interface AutentiConfiguration {
  documentName: string,
  tags: AutentiTag[],
  signatureModel: string,
  language: string,
  messageToParticipants: string,
  active: boolean,
  participationPriority: false,
  participant: AutentiParticipant[],
  participants: AutentiParticipant[],
}

export interface AutentiParticipant {
  tempId?: string,
  id?: string,
  companyRepresentative: boolean,
  taxPrefix: string,
  taxNumber: string,
  position: string,
  companyName: string,
  firstName: string,
  surname: string,
  email: string,
  role: string,
  signatureType: string,
  authorizationViaSmsCode: boolean,
  secureAccessToTheDocumentWithSmsCode: boolean,
  zipFileSecuredWithAnSmsCode: boolean,
  mobilePhone: null,
  priority?: number,
}