var render = function () {
  var _vm$selectedNetworks, _vm$missingNetworks, _vm$missingNetworks2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "sygniNetworkPicker",
    staticClass: "sygni-network-picker"
  }, [_c('div', {
    staticClass: "sygni-network-picker__inner"
  }, [_c('div', {
    staticClass: "sygni-network-picker__container"
  }, [_vm.selectedNetworksFiltered.length == 0 ? _c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v("No networks")]) : _c('div', {
    staticClass: "sygni-network-picker__counter text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.selectedNetworksLabel) + " "), _vm.counter ? _c('span', [_vm._v("... +" + _vm._s(_vm.counter))]) : _vm._e()])]), _c('div', {
    staticClass: "sygni-network-picker__dropdown-btn",
    on: {
      "click": function click($event) {
        _vm.showDropdown = true;
      }
    }
  }, [_c('img', {
    staticClass: "arrow-up",
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })])]), _c('b-popover', {
    attrs: {
      "target": function target() {
        return _vm.$refs.sygniNetworkPicker;
      },
      "placement": "bottom",
      "triggers": "hover",
      "customClass": "sygni-network-dropdown"
    }
  }, [_c('div', {
    staticClass: "sygni-network-dropdown__inner"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "sygni-network-dropdown__inner-body"
  }, [_vm.selectedNetworks.length == 0 ? _c('div', {
    staticClass: "sygni-network-dropdown__option sygni-network-dropdown__option--placeholder"
  }, [_vm._v("There are no networks to display")]) : _vm._e(), ((_vm$selectedNetworks = _vm.selectedNetworks) === null || _vm$selectedNetworks === void 0 ? void 0 : _vm$selectedNetworks.length) > 0 ? [((_vm$missingNetworks = _vm.missingNetworks) === null || _vm$missingNetworks === void 0 ? void 0 : _vm$missingNetworks.length) > 0 ? _c('div', {
    staticClass: "sygni-network-dropdown__option"
  }, [_c('sygni-link-button', {
    staticClass: "gn-secondary",
    on: {
      "click": _vm.selectAllNetworks
    }
  }, [_vm._v("Select all")])], 1) : _vm._e(), !((_vm$missingNetworks2 = _vm.missingNetworks) !== null && _vm$missingNetworks2 !== void 0 && _vm$missingNetworks2.length) ? _c('div', {
    staticClass: "sygni-network-dropdown__option"
  }, [_c('sygni-link-button', {
    staticClass: "red",
    on: {
      "click": _vm.deselectAllNetworks
    }
  }, [_vm._v("Deselect all")])], 1) : _vm._e()] : _vm._e(), _vm._l(_vm.selectedNetworks, function (network) {
    return _c('div', {
      key: network.id,
      staticClass: "sygni-network-dropdown__option",
      on: {
        "click": function click($event) {
          return _vm.toggleNetwork(network);
        }
      }
    }, [_c('p', {
      staticClass: "sygni-network-dropdown__option-text"
    }, [_vm._v(_vm._s(network.name))]), _vm.isCoordinator(network.id) ? _c('img', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: {
          interactive: false,
          boundary: 'viewport'
        },
        expression: "{ interactive: false, boundary: 'viewport' }",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "mr-2",
      attrs: {
        "src": _vm._f("getIcon")('APPROVE 2'),
        "title": "Coordinator",
        "alt": ""
      }
    }) : _vm._e(), _c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "value": network.value,
        "disabled": _vm.disabled
      }
    })], 1);
  })], 2), !_vm.disabled ? _c('div', {
    staticClass: "sygni-network-dropdown__btn",
    on: {
      "click": function click($event) {
        return _vm.$emit('openNetworkManagementModal');
      }
    }
  }, [_vm._v("Manage")]) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }