
































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import GenproxModal from "@/components/layout/GenproxModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BTable, BvTableField, BTooltip } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import SygniNetworkPicker from '@/components/inputs/SygniNetworkPicker.vue';
import SubscriptionsInnerTable from './SubscriptionsInnerTable.vue';
import { Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { Dictionaries } from '../../portfolio/store/types';
import { CheckboxOption } from '@/store/types';
import SygniProgressBar from '@/components/layout/SygniProgressBar.vue';
import LandingPageModal from './LandingPagePreviewModal.vue';
import { create, all } from 'mathjs'
const math = create(all);
import _ from 'lodash';
import PlusCircleIcon from '@/assets/icons/heroicons/PlusCircleIcon.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, BTooltip, SygniSelect, SygniCheckbox, SygniNetworkPicker, SubscriptionsInnerTable, SygniSquareButton, SygniRectButton, GenproxModal, SygniProgressBar, LandingPageModal, GenproxButton, PlusCircleIcon
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    }),
    ...mapState('subscriptions', {
      itemsLength: (state: any) => state.subscriptionsTable?.totalCount,
    }),
  }
})
export default class SubscriptionsTable extends SygniTable<any> {
  PlusCircleIcon = PlusCircleIcon;
  showNetworkManagementModal: boolean = false;
  showNetworkAccessModal: boolean = false;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  deletingCampaignId: string | null = null;
  isLandingPageModalLoading: boolean = false;
  subscriptionConfirmationModalId: string = null;
  isSubscriptionModalLoading: boolean = false;
  showLandingPageModal: boolean = false;
  dictionaries!: Dictionaries;
  isLoading: boolean = false;
  refresh: any = null;

  passConfirmModal: any = {
    show: false,
    isLoading: false,
    header: 'Are you sure?',
    subscription: null,
  }

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'code', sortable: true, class: 'table__head-cell left ', label: 'Code' },
    { key: 'sourceName', sortable: true, class: 'table__head-cell', borderless: true, label: 'Source' },
    { key: 'agreementDate', sortable: true, class: 'table__head-cell', borderless: false, label: 'Agreement Date' },
    { key: 'target', sortable: false, class: 'table__head-cell', label: 'Target' },
    // { key: 'instrumentCount', sortable: false, class: 'table__head-cell', label: 'Instrument Count' },
    { key: 'seriesOfShares', sortable: false, class: 'table__head-cell', label: 'Series of shares' },
    { key: 'investmentReach', sortable: true, class: 'table__head-cell text-right', label: 'Investment Reach' },
    { key: 'capitalValue', sortable: false, class: 'table__head-cell text-right', label: 'Capital Value' },
    { key: 'investmentValue', sortable: false, class: 'table__head-cell text-right', label: 'Investment Value' },
    { key: 'registrationStatus', sortable: true, class: 'table__head-cell', label: 'Reg. Status' },
    { key: 'status', sortable: true, class: 'table__head-cell', label: 'Status' },
    { key: 'actions', sortable: false, class: 'table__head-cell', label: 'Actions' }
  ];

  tableFieldsLlc: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'code', sortable: true, class: 'table__head-cell left ', label: 'Code' },
    { key: 'sourceName', sortable: true, class: 'table__head-cell', borderless: true, label: 'Source' },
    { key: 'agreementDate', sortable: true, class: 'table__head-cell', borderless: false, label: 'Agreement Date' },
    { key: 'target', sortable: false, class: 'table__head-cell', label: 'Target' },
    { key: 'instrumentCount', sortable: false, class: 'table__head-cell', label: 'Instrument Count' },
    // { key: 'seriesOfShares', sortable: false, class: 'table__head-cell', label: 'Series of shares' },
    { key: 'investmentReach', sortable: true, class: 'table__head-cell text-right', label: 'Investment Reach' },
    { key: 'capitalValue', sortable: false, class: 'table__head-cell text-right', label: 'Capital Value' },
    { key: 'investmentValue', sortable: false, class: 'table__head-cell text-right', label: 'Investment Value' },
    { key: 'registrationStatus', sortable: true, class: 'table__head-cell', label: 'Reg. Status' },
    { key: 'status', sortable: true, class: 'table__head-cell', label: 'Status' },
    { key: 'actions', sortable: false, class: 'table__head-cell', label: 'Actions' }
  ];

  get passConfirmModalDescription() {
    const subscription = this.passConfirmModal?.subscription

    const exceededAmount = math.number(math.subtract(math.bignumber(subscription?.investmentIssueReach || 0), math.bignumber(subscription?.investmentValue || 0)));
    return `The sum of the issue capital exceeds the assumed one by ${this.$options.filters.numberFormat(exceededAmount, 2)} PLN. Are you sure you want to close the subscription?`
  }

  get totals() {
    let totals: any = {
      investmentReach: {},
      capitalValue: {},
      investmentValue: {},
    };

    this.items.forEach((item: any) => {
      const keys = Object.keys(totals)
      const currency = 'PLN'
      keys.forEach((key: string) => {
        if (key !== 'investmentValue') {
          if (totals[key][currency]) {
            totals[key][currency] = item[key] ? math.number(math.add(math.bignumber(totals[key][currency]), math.bignumber(item[key]))) : math.number(math.add(math.bignumber(totals[key][currency]), 0))
          } else {
            totals[key][currency] = item[key] ? math.bignumber(math.bignumber(item[key])) : 0;
          }
        } else {
          const usedKey = item?.status?.toUpperCase() === 'PASSED' ? 'investmentIssueReach' : 'investmentValue'
          if (totals['investmentValue'][currency]) {
            totals['investmentValue'][currency] = item[usedKey] ? math.number(math.add(math.bignumber(totals['investmentValue'][currency]), math.bignumber(item[usedKey]))) : math.number(math.add(math.bignumber(totals['investmentValue'][currency]), 0))
          } else {
            totals['investmentValue'][currency] = item[usedKey] ? math.bignumber(math.bignumber(item[usedKey])) : 0;
          }
        }
      })
    })

    return totals;
  }

  get isLlc() {
    return this.activeUserData?.equityType !== 'JOINT_STOCK_SHARES'
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  isPassVisible(subscription: any) {
    const isForceable = !(subscription?.errors?.filter((el: any) => el.couldBeForced === false)?.length)

    return isForceable && subscription?.status?.toUpperCase() !== 'PASSED'
  }

  isPassAvailable(subscription: any) {
    return this.isPassVisible && subscription?.errors?.length === 0 && subscription?.investmentReach == subscription?.capitalValue && subscription?.investmentValue >= subscription?.investmentReach
  }

  isCancelAvailable(subscription: any) {
    return subscription?.status?.toUpperCase() === 'NEW'
  }

  isAddAvailable(subscription: any) {
    return subscription?.status?.toUpperCase() === 'NEW' && (subscription?.investmentReach < subscription?.capitalValue)
  }

  showSubscriptionActions(subscription: any) {
    return (this.isPassAvailable(subscription) || this.isCancelAvailable(subscription) || this.isAddAvailable(subscription)) && !this.isMarketplaceAccountant
  }

  goToSourceProfile(sourceId: string): void {
    if (sourceId) {
      this.$router.push({ name: 'profile-dashboard-guest', params: { id: sourceId } });
    }
  }

  get tableFieldsComputed() {
    if (this.isLlc) {
      return this.tableFieldsLlc
    }

    return this.tableFields
  }

  get statusItems() {
    let statuses: Array<string> = _.uniq(this.items.map((el: any) => {
      return el.status.toUpperCase();
    }));

    return statuses;
  }

  get formattedActionItems() {
    return this.statusItems.map((value: string, index: number) => {
      return {
        id: index + 1,
        key: value,
      }
    })
  }

  get campaignData() {
    return this.$store.getters['subscriptions/getCampaignData']
  }

  closeSubscriptionConfirmationModal() {
    this.subscriptionConfirmationModalId = null
  }

  async forceSubscriptionPass() {
    this.$store.commit(this.setTableBusyMutation, true)
    this.isSubscriptionModalLoading = true
    
    try {
      await this.$store.dispatch('subscriptions/forceSubscriptionPass', this.subscriptionConfirmationModalId)
      this.closeSubscriptionConfirmationModal()
      await this.refreshTable()
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    
    this.$store.commit(this.setTableBusyMutation, false)
    this.isSubscriptionModalLoading = false
  }

  async getSubscriptionErrors(id: string) {
    try {
      const subscription = await this.$store.dispatch('subscriptions/getSubscription', id)

      return subscription?.errors || []
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async openDetails(rowData: any) {
    this.$store.commit(this.setTableBusyMutation, true);
    rowData.item.details = []
    
    if (!rowData.item._showDetails) {
      const errors = await this.getSubscriptionErrors(rowData.item.id);
      rowData.item.details = await this.$store.dispatch('subscriptions/getSubscriptionProducts', rowData.item.id);
  
      if (errors) {
        rowData.item.errors = errors
      }
    } else {
      rowData.item.errors = []
    }


    this.$store.commit(this.setTableBusyMutation, false);
    rowData.toggleDetails();
  }

  closePassConfirmModal() {
    this.passConfirmModal.show = false
    this.passConfirmModal.subscription = null
  }

  openPassConfirmModal(subscription: any) {
    const exceededAmount = math.number(math.subtract(math.bignumber(subscription?.investmentIssueReach || 0), math.bignumber(subscription?.investmentValue || 0)));

    if (exceededAmount > 0) {
      this.passConfirmModal.show = true
      this.passConfirmModal.subscription = subscription
    } else {
      this.openSubscriptionPassModal(subscription)
    }
  }

  async openSubscriptionPassModal(subscription: any) {
    this.closePassConfirmModal();
    if (this.isPassAvailable(subscription)) {
      await this.handleSubscriptionAction('pass', subscription?.id)
    } else {
      this.subscriptionConfirmationModalId = subscription?.id
    }
  }

  async handleSubscriptionAction(type: 'cancel' | 'pass', id: string) {
    this.$store.commit(this.setTableBusyMutation, true)
    
    try {
      const action = type === 'cancel' ? 'cancelSubscription' : 'passSubscription'
      await this.$store.dispatch(`subscriptions/${action}`, id)
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: `Subscription ${type === 'cancel' ? 'cancelled' : 'authorized'}.`
      })
      await this.refreshTable()
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.$store.commit(this.setTableBusyMutation, false)
  }

  // async onRowClickHandler(item: any) {
  //   this.isLandingPageModalLoading = true;
  //   this.showLandingPageModal = true;
  //   try {
  //     const response = await this.$store.dispatch('subscriptions/getCampaign', item.id);
  //     this.$store.commit('subscriptions/setCampaignData', response)
  //   } catch (error) {
  //     this.showLandingPageModal = false;
  //     const errorMessage = this.$options.filters.errorHandler(error);
  //     this.$notify({
  //       duration: 2500,
  //       type: 'error',
  //       title: 'Error',
  //       text: this.$t(errorMessage).toString()
  //     });
  //   }
  //   this.isLandingPageModalLoading = false;
  // }

  hasError(subscription: any) {
    return subscription?.errors?.find((el: any) => !el?.productId)
  }

  getErrorMessage(subscription: any) {
    const errors = subscription?.errors?.filter((el: any) => !el?.productId)

    return errors?.map((el: any) => el.errorMsg)?.join(', ') || ''
  }

  addNewProduct(subscriptionId: string) {
    this.$router.push({ name: 'addNewProductStepOne', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/new/step-one`, params: { subscriptionId, productType: this.isLlc ? 'LLC_SHARES' : 'JOINT_STOCK_SHARES' } })
  }

  closeLandingPageModal() {
    this.isLandingPageModalLoading = false;
    this.showLandingPageModal = false;
    this.$store.commit('subscriptions/clearCampaignData');
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.subscriptionsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.subscriptionsTable as BTable).selectAllRows();
    } else {
      (this.$refs.subscriptionsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach((row: any) => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  async refreshTable() {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(async () => {
      await this.getItems();
    })
  }

  getStatusClassName(status: string): string {
    let className = 'success';
    switch (status.toUpperCase()) {
      case ('PASSED'):
        className = 'primary';
        break;
      case ('CANCELLED'):
        className = 'danger';
        break;
      case ('ACTIVE'):
        className = 'primary';
        break;
    }

    return className;
  }

  statusText(status: string): string {
    if (status?.toLowerCase() === 'passed') {
      return 'Authorized'
    }

    return this.$options.filters.capitalizeFirstLetter(status.toLowerCase());
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('subscriptions/setSubscriptionsTableFiltersQuery', filtersQuery);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('subscriptions/setSubscriptionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('subscriptions/setSubscriptionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  setQuery() {
    this.$store.commit('subscriptions/setSubscriptionsTableQuery', this.localTableQuery);
  }

  getIndustryLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.industry.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getStageLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.stage.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentClassLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentClass.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentTypeLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentType.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  get viewedId() {
    if (this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' ? this.viewedCompanyId : '';
    } else {
      return this.activeUserData.context.context !== 'fund' ? this.viewedFundId : '';
    }
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  openDeleteConfirmationModal(campaignId: string) {
    this.deletingCampaignId = campaignId;
  }

  closeDeleteConfirmationModal() {
    this.deletingCampaignId = null;
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, '');
  }

  get filtersQuery() {
    return this.$store.getters['subscriptions/getSubscriptionsTableFiltersQuery'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.subscriptionsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('subscriptions/setSubscriptionsTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }
}

