var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-table"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "kycTable",
    staticClass: "sygni-b-table",
    attrs: {
      "id": "fe_investors_grid",
      "fields": _vm.computedTableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "tbody-class": "fe_investors_grid_tbody",
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "row-clicked": _vm.rowClickHandler
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(investorEmail)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(_vm._s(rowData === null || rowData === void 0 ? void 0 : (_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.investorEmail))])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        var _rowData$item$status;

        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "filled round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          },
          on: {
            "click": _vm.rowClickHandler
          }
        }, [_vm._v(" " + _vm._s((_rowData$item$status = rowData.item.status) === null || _rowData$item$status === void 0 ? void 0 : _rowData$item$status.toUpperCase()) + " ")])], 1)];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__source"
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName))])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.createdAt)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        var _rowData$item2;

        return [_c('SygniTableActions', {
          attrs: {
            "id": rowData === null || rowData === void 0 ? void 0 : (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.id,
            "itemData": rowData === null || rowData === void 0 ? void 0 : rowData.item,
            "getRowActions": _vm.getRowActions
          }
        })];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'investorEmail' ? _c('div', [_c('div', {
            staticClass: "totals totals--align-left"
          }, [_c('div', {
            staticClass: "totals__left"
          }, [_c('p', [_c('strong', [_vm._v("Total investors:")])])]), _c('div', {
            staticClass: "totals__right"
          }, [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0) + " / " + _vm._s(_vm.itemsLength))])])])]) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }